<script>
export default {
  data() {
    return {
      role: null,
      orgId: null,
    };
  },
  created() {
    this.role = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );
    this.orgId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );

    if (this.role) {
      this.setDefaultClinicWithStorePermission();
    }
  },
  methods: {
    getRoleRouteName(routeName) {
      /** uncomment code below wil use  org owner route names*/
      // return `OrgOwner${routeName}`

      let routePrefix = null;
      let userType = this.$store.state.AppActiveUser.userRole.toLowerCase();

      // exclusive for this route cause of too many similar route names
      if (routeName == "OrderList" && userType == "doctor")
        return `DoctorStaffOrderList`;

      if (this.role && this.role.toLowerCase() == "org owner") routePrefix = "OrgOwner";
      else if (this.role && this.role.toLowerCase() == "staff" && userType == "doctor")
        routePrefix = "Doctor";
      else if (this.role && this.role.toLowerCase() == "staff" && userType == "nurse")
        routePrefix = "Nurse";
      else if (this.role && this.role.toLowerCase() == "staff" && userType == "basic") {
        routePrefix = "Basic";
      } else if (userType === "superadmin") {
        routePrefix = "SuperAdmin"
      }
      
      return `${routePrefix}${routeName}`;
    },
    async getOrganizationAllowedClinics() {
      // exclusive for orgowner only
      let response = await this.$store.dispatch(
        "organization/fetchOrganizationClinicsList",
        {
          id: localStorage.getItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
          ),
          limit: 200,
          sort: "clinicName",
          dir: "asc",
        }
      );

      if (response.data) {
        const results = response.data.data.docs.filter(
          ({ showStore }) => showStore
        );

        if (results.length){
          if  (!sessionStorage.getItem("doctorClinicSelectedClinic")) {
            sessionStorage.setItem("doctorClinicSelectedClinic", results[0]._id);
          }
          this.$store.commit("SHOW_STORE", true);
        } 
      }
    },
    setDefaultClinicWithStorePermission() {
      let userClinics = this.$store.state.AppActiveUser.associatedClinicIds;
      let orgClinics = this.getSelectedOrganizationClinics();

      let clinicsPermitted = orgClinics.filter((orgClinic) => {
        return userClinics.find(
          (userClinic) =>
            userClinic.clinicId && orgClinic.clinicId == userClinic.clinicId._id &&
            userClinic.clinicId.showStore
        );
      });
      
      if (clinicsPermitted.length) {
        const clinic = sessionStorage.getItem("doctorClinicSelectedClinic");
        if (!clinic || !clinic.length) {
          sessionStorage.setItem(
            "doctorClinicSelectedClinic",
            clinicsPermitted[0].clinicId
          );
        }
        this.$store.commit("SHOW_STORE", true);
      } else {
        this.$store.commit("SHOW_STORE", false);
      }
    },
    getSelectedOrganizationClinics() {
      let organizations = this.$store.state.AppActiveUser.organizations;
      let selectedOrganization = organizations.find(
        (org) => org._id == this.orgId
      );

      return selectedOrganization.clinics;
    },
  }
};
</script>
