var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1"},[(_vm.checkBankDetailExists && this.$isAuRegion())?_c('div',{staticClass:"flex items-center justify-center space-x-4 w-full"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('feather-icon',{staticClass:"text-red-600",attrs:{"icon":"AlertTriangleIcon"}}),_c('p',{staticClass:"text-red-600 text-sm lg:text-base font-semibold leading-tight"},[_vm._v(" You haven't setup bank details yet! ")])],1),_c('vs-button',{staticClass:"py-1 lg:py-3 text-sm lg:text-base",on:{"click":_vm.setupBankDetail}},[_vm._v("Setup Now")])],1):_vm._e(),(this.$isAuRegion())?_c('vs-popup',{staticClass:"holamundo",staticStyle:{"z-index":"51001 !important"},attrs:{"title":"Setup payment method","active":_vm.popupShow},on:{"update:active":function($event){_vm.popupShow=$event}}},[_c('div',{staticStyle:{"display":"flex"}},[_c('h6',{staticStyle:{"line-height":"1.5"}},[_vm._v(" Hey! Looks like you haven't set up a default Payment Method for your monthly scripting. Select 'Setup Now' to continue. ")])]),_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-1 mt-5"},[_c('div',{staticStyle:{"display":"inline-block","margin-right":"5px"}},[_c('vs-button',{on:{"click":_vm.hidePopup}},[_vm._v("Later")])],1),_c('vs-button',{on:{"click":_vm.setupBankDetail}},[_vm._v("Setup Now")])],1)])],1)],1):_vm._e(),_c('vs-popup',{staticClass:"holamundo",staticStyle:{"z-index":"51001 !important"},attrs:{"title":"Setup Bank Details","active":_vm.popupActivo},on:{"update:active":function($event){_vm.popupActivo=$event}}},[(
        !_vm.$store.state.AppActiveUser.identityDocument ||
          !_vm.$store.state.AppActiveUser.identityDocument.front ||
          !_vm.$store.state.AppActiveUser.identityDocument.back ||
          !_vm.$store.state.AppActiveUser.stripeConnectedAccountVerifiedStatus
      )?_c('div',[_c('div',{staticStyle:{"display":"flex"}},[_c('h6',[_vm._v("Identity Document")]),_c('vx-tooltip',{attrs:{"position":"bottom","text":"Please upload Driving licence or Passport or Identity card. Scans of both the front and back are usually required for government-issued IDs and driver's licenses. A passport scan is always acceptable and preferred. Files need to be JPEGs or PNGs smaller than 10MB. We cannot verify PDFs. Files should be rotated with the image right-side up, and have all information clearly legible."}},[_c('feather-icon',{staticClass:"ml-1",staticStyle:{"cursor":"pointer"},attrs:{"icon":"InfoIcon","svgClasses":"h-4 w-4"}})],1)],1),_c('vs-row',{staticClass:"my-5",attrs:{"vs-align":"flex-start","vs-type":"flex","vs-justify":"left","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{class:{ dateClass: !_vm.errors.has('identityDocumentFront') },staticStyle:{"width":"93%"}},[_c('label',{staticClass:"vs-input--label custom-label"},[_vm._v("Front")]),_c('div',{staticStyle:{"margin-top":"3px"}},[(_vm.bankDetails.identityDocument.front)?_c('div',{staticClass:"mb-4"},[(
                    _vm.determineFileType(_vm.bankDetails.identityDocument.front) ===
                      'pdf'
                  )?_c('div',{staticStyle:{"margin":"auto","width":"50%"}},[_c('a',{attrs:{"href":_vm.bankDetails.identityDocument.front,"target":"_blank"}},[_vm._v("Click here to view file")])]):_c('div',{staticClass:"img-wrapper",staticStyle:{"margin":"auto"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.bankDetails.identityDocument.front,"alt":""}})])]):_vm._e(),_c('div',{staticClass:"vs-con-loading__container",class:{ 'mt-3': _vm.bankDetails.identityDocument.front },staticStyle:{"text-align":"center","position":"relative"},attrs:{"id":"identityDocumentFrontUpload"}},[_c('label',{staticClass:"image-upload front-document-class vs-con-loading__container",class:{
                    'six-pixel': _vm.errors.has('identityDocumentFront')
                  },staticStyle:{"border":"1px solid #cccccc","display":"inline-block","width":"100%","border-radius":"5px","padding":"10px"},attrs:{"id":"identityDocumentFrontId"}},[_vm._v(" + Attach Document Front "),_c('input',{attrs:{"type":"file","accept":"image/*","id":"file-input","hidden":""},on:{"change":function($event){return _vm.uploadImage(
                        $event,
                        'identityDocumentFrontUpload',
                        'front-document-class'
                      )}}})])])]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("identityDocumentFront"))+" ")])])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{class:{ dateClass: !_vm.errors.has('identityDocumentBack') },staticStyle:{"width":"98%"}},[_c('label',{staticClass:"vs-input--label custom-label"},[_vm._v("Back")]),_c('div',{staticStyle:{"margin-top":"3px"}},[(_vm.bankDetails.identityDocument.back)?_c('div',{staticClass:"mb-4"},[(
                    _vm.determineFileType(_vm.bankDetails.identityDocument.back) ===
                      'pdf'
                  )?_c('div',{staticStyle:{"margin":"auto","width":"50%"}},[_c('a',{attrs:{"href":_vm.bankDetails.identityDocument.back,"target":"_blank"}},[_vm._v("Click here to view file")])]):_c('div',{staticClass:"img-wrapper",staticStyle:{"margin":"auto"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.bankDetails.identityDocument.back,"alt":""}})])]):_vm._e(),_c('div',{staticClass:"vs-con-loading__container",class:{ 'mt-3': _vm.bankDetails.identityDocument.back },staticStyle:{"text-align":"center","position":"relative"},attrs:{"id":"identityDocumentBackUpload"}},[_c('label',{staticClass:"image-upload back-document-class vs-con-loading__container",class:{ 'six-pixel': _vm.errors.has('identityDocumentBack') },staticStyle:{"border":"1px solid #cccccc","display":"inline-block","width":"100%","border-radius":"5px","padding":"10px"},attrs:{"id":"identityDocumentBackId"}},[_vm._v(" + Attach Document Back "),_c('input',{attrs:{"type":"file","accept":"image/*","id":"file-input","hidden":""},on:{"change":function($event){return _vm.uploadImage(
                        $event,
                        'identityDocumentBackUpload',
                        'back-document-class'
                      )}}})])])]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("identityDocumentBack"))+" ")])])])],1),_c('vs-divider')],1):_vm._e(),_c('vs-row',{attrs:{"vs-align":"flex-start","vs-type":"flex","vs-justify":"left","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-1 mr-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full mt-6",attrs:{"danger":_vm.errors.first('accountHolderName') ? true : false,"success":!_vm.errors.first('accountHolderName') &&
                _vm.bankDetails.accountHolderName != '',"val-icon-success":"done","val-icon-danger":"error","data-vv-validate-on":"blur","data-vv-as":"Account Holder Name","label-placeholder":"Account Holder Name","name":"accountHolderName","placeholder":"Account Holder Name"},model:{value:(_vm.bankDetails.accountHolderName),callback:function ($$v) {_vm.$set(_vm.bankDetails, "accountHolderName", $$v)},expression:"bankDetails.accountHolderName"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("accountHolderName")))])],1)]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-1"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|digits:6'),expression:"'required|digits:6'"}],staticClass:"w-full mt-6",attrs:{"danger":_vm.errors.first('bsb') ? true : false,"success":!_vm.errors.first('bsb') && _vm.bankDetails.bsb != '',"val-icon-success":"done","val-icon-danger":"error","data-vv-validate-on":"blur","data-vv-as":"BSB","label-placeholder":"BSB","name":"bsb","placeholder":"BSB"},model:{value:(_vm.bankDetails.bsb),callback:function ($$v) {_vm.$set(_vm.bankDetails, "bsb", $$v)},expression:"bankDetails.bsb"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("bsb")))])],1)]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-1 mr-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full mt-6",attrs:{"danger":_vm.errors.first('accountNumber') ? true : false,"success":!_vm.errors.first('accountNumber') &&
                _vm.bankDetails.accountNumber != '',"val-icon-success":"done","val-icon-danger":"error","data-vv-validate-on":"blur","data-vv-as":"Account Number","label-placeholder":"Account Number","name":"accountNumber","placeholder":"Account Number"},model:{value:(_vm.bankDetails.accountNumber),callback:function ($$v) {_vm.$set(_vm.bankDetails, "accountNumber", $$v)},expression:"bankDetails.accountNumber"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("accountNumber")))])],1)])],1),_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-1 mt-5"},[_c('vs-button',{attrs:{"disabled":!_vm.validateForm},on:{"click":_vm.saveBankDetails}},[_vm._v("Save Details")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }