<template>
  <vs-dropdown
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer"
    id="notificationMenu"
  >
    <feather-icon
      icon="BellIcon"
      class="cursor-pointer mt-1 sm:mr-6 mr-2"
      :badge="badgeCount ? badgeCount : initBadge"
    />

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown"
    >
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ $store.state.AppActiveUser.badge ? $store.state.AppActiveUser.badge : initBadge }} New</h3>
        <p class="opacity-75">Notifications</p>
        <div id="notiDropDown"></div>
      </div>

      <VuePerfectScrollbar
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul class="bordered-items">
          <li
            @click="notificationClicked(ntf)"
            v-for="ntf in unreadNotifications"
            :key="ntf.index"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
            :style="ntf.status === 'unread' ? 'background: aliceblue;' : ''"
          >
            <a
              :href="ntf.link"
              class="flex justify-between notification cursor-pointer"
              :class="ntf.status"
            >
              <div class="flex items-start">
                <feather-icon
                  :icon="ntf.icon"
                  :svgClasses="[
                    `text-${ntf.category}`,
                    'stroke-current mr-1 h-6 w-6'
                  ]"
                ></feather-icon>
                <div class="mx-2">
                  <span
                    class="font-medium block notification-title"
                    style="font-weight: 500!important; display: block!important;color: #626262;"
                    :class="[`text-${ntf.category}`]"
                    >{{ ntf.title }}</span
                  >
                  <small style="color: #626262;">{{ ntf.msg }}</small>
                </div>
              </div>
            </a>
            <small style="color: #3DC9B3;" class="mt-1 whitespace-no-wrap">{{
              elapsedTime(ntf.time)
            }}</small>
          </li>
        </ul>
      </VuePerfectScrollbar>

      <div
        class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
      >
        <a :href="viewAllNotificationLink">View All Notifications</a>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
export default {
  name: "notificationDropdown",
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      unreadNotifications: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      notificationOptions: {
        sort: "createdAt",
        dir: "desc",
        limit: 10,
        page: 1
      },
      loggedInUserType: this.$store.state.AppActiveUser.userType,
      viewAllNotificationLink: `/${this.loggedInUserType}/notifications`,
      initBadge: 0
    };
  },
  methods: {
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      let date = new Date();

      if (hr) date.setHours(date.getHours() - hr);
      if (min) date.setMinutes(date.getMinutes() - min);
      if (sec) date.setSeconds(date.getSeconds() - sec);

      return date;
    },
    fecthNotifCount() {
      this.$store
        .dispatch("general/fetchNotifications", this.notificationOptions)
        .then(response => {
          if (response.data.data.docs.length > 0) {
            this.initBadge = response.data.data.docs.length
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getNotifications() {
         
      this.$vs.loading({
        text: "Fetching ...",
        color: "#3dc9b3",
        background: "white",
        container: "#notiDropDown"
      });

      this.unreadNotifications = [];
      
      this.$store
        .dispatch("general/fetchNotifications", this.notificationOptions)
        .then(response => {
          if (response.data.data.docs.length > 0) {
            this.unreadNotifications = [];
            response.data.data.docs.map(datum => {
              let link = "javascript:void(0)";

              if (
                datum.type === "AHPRA Expiry" ||
                datum.type === "AHPRA Expiry Account Disabled" ||
                datum.type === "Indemnity Insurance Expiry" ||
                datum.type === "Indemnity Insurance Expiry Account Disabled" ||
                datum.type === "Updated Profile Approved" ||
                datum.type === "Updated Profile Rejected"
              ) {
                link = `/${this.$store.state.AppActiveUser.userType}/profile`;
              } else if( datum.type ==='New Order'){
                const parsedData = JSON.parse(datum.metaData);
                let userType =this.$store.state.AppActiveUser.userType;
                if(userType ==='superAdmin'){
                  userType ='super-admin';
                }
                link = `/${userType}/store/orders/view/`+parsedData.orderId;

              }
              else if (
                datum.type === "Doctor Pending Treatment Notes" ||
                datum.type === "Nurse Pending Treatment Notes"
              ) {
                const parsedData = JSON.parse(datum.metaData);
                let notificationBackRoute = "doctorNotification";
                if (this.$store.state.AppActiveUser.userType === "nurse") {
                  notificationBackRoute = "nurseNotification";
                }
                link = `/${this.$store.state.AppActiveUser.userType}/patients/${parsedData.patientId}/treatment/${parsedData.checkInId}?backRoute=${notificationBackRoute}`;
              } else if (datum.type === "Clinic Join Request") {
                const parsedData = JSON.parse(datum.metaData);
                link = `/${this.$store.state.AppActiveUser.userType}/clinics/${parsedData.clinicId}/respond-clinic-join-request/${parsedData.requestId}`;
              } else if (datum.type === "User Registration") {
                const parsedData = JSON.parse(datum.metaData);
                // const loggedInUserType =
                //   this.$store.state.AppActiveUser.userType === "superAdmin"
                //     ? "super-admin"
                //     : this.$store.state.AppActiveUser.userType;

                if (parsedData.userType === "doctor")
                  link = `/${this.loggedInUserType}/doctors/${parsedData.userId}/edit`;
                if (parsedData.userType === "nurse")
                  link = `/${this.loggedInUserType}/nurses/${parsedData.userId}`;
                if (parsedData.userType === "franchise")
                  link = `/${this.loggedInUserType}/franchises/${parsedData.userId}`;
                if (parsedData.userType === "clinic")
                  link = `/${this.loggedInUserType}/clinics/${parsedData.userId}/edit`;
              } else if (datum.type === "User Created") {
                const parsedData = JSON.parse(datum.metaData);
                // const loggedInUserType =
                //   this.$store.state.AppActiveUser.userType === "superAdmin"
                //     ? "super-admin"
                //     : this.$store.state.AppActiveUser.userType;

                if (parsedData.userType === "doctor")
                  link = `/${this.loggedInUserType}/doctors/${parsedData.userId}/edit`;
                if (parsedData.userType === "nurse")
                  link = `/${this.loggedInUserType}/nurses/${parsedData.userId}`;
                if (parsedData.userType === "franchise")
                  link = `/${this.loggedInUserType}/franchises/${parsedData.userId}`;
                if (parsedData.userType === "clinic")
                  link = `/${this.loggedInUserType}/clinics/${parsedData.userId}/edit`;
              } else if (datum.type === "Account Update Request") {
                const parsedData = JSON.parse(datum.metaData);
                // const loggedInUserType =
                //   this.$store.state.AppActiveUser.userType === "superAdmin"
                //     ? "super-admin"
                //     : this.$store.state.AppActiveUser.userType;

                if (parsedData.userType === "doctor")
                  link = `/${this.loggedInUserType}/doctors/${parsedData.userId}/profile-update-review`;
                if (parsedData.userType === "nurse")
                  link = `/${this.loggedInUserType}/nurses/${parsedData.userId}/profile-update-review`;
              } else if (datum.type === "Treatment Outcome Updated") {
                const parsedData = JSON.parse(datum.metaData);
                link = `/${this.$store.state.AppActiveUser.userType}/patients/${parsedData.patientId}/treatment/${parsedData.checkInId}?backRoute=nurseNotification`;
              } else if (datum.type === "Appointment Updated") {
                const parsedData = JSON.parse(datum.metaData);
                link = `/${this.$store.state.AppActiveUser.userType}/patients/${parsedData.patientId}/treatment/${parsedData.checkInId}?backRoute=doctorNotification`;
              } else if (
                datum.type === "Clinic Invoice Payment Succeeded" ||
                datum.type === "Clinic Invoice Payment Failed"
              ) {
                const parsedData = JSON.parse(datum.metaData);
                link = `/${this.loggedInUserType}/invoice`;
              } else if (
                datum.type === "Doctor Payout Succeeded" ||
                datum.type === "Doctor Payout Failed"
              ) {
                const parsedData = JSON.parse(datum.metaData);
                link = `/${this.loggedInUserType}/doctor-aggregated-report`;
              } else if (datum.type === "Permission Updated") {
                link = `/${this.loggedInUserType}/permission-denied`;
              } else {
                link = `/${this.loggedInUserType}/notifications`;
              }

              const makeArrayItem = {
                title: datum.title,
                msg: datum.message,
                time: datum.createdAt,
                category: datum.type,
                status: datum.status,
                _id: datum._id,
                metaData: datum.metaData,
                link: link
              };
              this.unreadNotifications.push(makeArrayItem);
              this.$store.state.badgeCount = this.unreadNotifications.length;
            });
          }
          this.$vs.loading.close("#notiDropDown > .con-vs-loading");
        })
        .catch(err => {
          console.log(err);
          this.$vs.loading.close("#notiDropDown > .con-vs-loading");
        });
    },
    notificationClicked(datum) {
      this.readNotification(datum._id);
    },
    readNotification(id) {
      this.$store
        .dispatch("general/readNotifications", id)
        .then(response => {
          for (var i in this.unreadNotifications) {
            if (this.unreadNotifications[i]._id == id) {
              this.unreadNotifications[i].status = "read";
              break;
            }
          }
        })
        .catch(err => console.log(err));
    },
    clearBadgeCount() {
      this.$store
        .dispatch("general/clearBadgeCount")
        .then(response => {
          this.$store.state.badgeCount = 0;
        })
        .catch(err => console.log(err));
    }
    // viewAllNotifications() {
    //   this.$router.push("/doctor/notifications");
    // }
  },
  computed: mapState(["badgeCount"]),
  watch: {
    badgeCount(newValue, oldValue) {
      // Do whatever makes sense now
      if (newValue !== oldValue && newValue > 0) {
        this.getNotifications();
      }
    }
  },
  created() {
    if(localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
        ) === "Org Owner"){
          this.loggedInUserType = "org-owner"
    }
    else{
      this.loggedInUserType =
      this.$store.state.AppActiveUser.userType === "superAdmin"
        ? "super-admin"
        : this.$store.state.AppActiveUser.userType;
    }

    this.viewAllNotificationLink = `/${this.loggedInUserType}/notifications`;
    this.fecthNotifCount();
  },
  mounted() {
    this.$nextTick(function() {
      let vueObj = this;
      const notifDOM = document.querySelector("#notificationMenu")

      if (notifDOM) {
        notifDOM.addEventListener("click", function() {
          vueObj.clearBadgeCount();
          vueObj.getNotifications();
        });
      }
    });
  }
};
</script>

<style scoped>
a.unread {
  opacity: 1 !important;
  box-shadow: none !important;
  background: none !important;
}
.bg-primary{
  background-color: rgba(var(--vs-primary), 1) !important;
}
</style>
